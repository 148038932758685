import React from 'react';
import Layout from "../components/layout";
import Block from "../components/Block";
import People from '../assets/people.png';
import Component from "../components/Component";
const PaymentError = () => (
  <Layout pageTitle="Payment">
    <div className="page-content">
    <Block className="">
    <Component>
      <h1>Payment error</h1>
      <p>There was an error with your payment. Please try again or email <a href='mailto:uikit@adapt.tips?subject=Payment error'>uikit@adapt.tips</a> if you errors persist.</p>
    </Component>
    </Block>
    </div>
  </Layout>
)
export default PaymentError;
